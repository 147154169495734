import React from "react";
import { Helmet } from "react-helmet";

import { useDefaultSeoQuery } from "@query";
import { SanitySeo, Maybe, SanityRegion, SanityHero } from "@graphql-types";
import { setBaseUrl } from "@util/helper";
import { useStore } from "@state/store";
import { isSanityImageWithMeta } from "@util/types";
interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug: string;
  alternateRegions?: (Maybe<SanityRegion> | undefined)[];
  heroData?: Maybe<SanityHero> | undefined;
}

const SEO = (props: Props) => {
  const {
    region: { iban },
  } = useStore();
  const data = useDefaultSeoQuery(iban);
  const { slug, seoData, alternateRegions, heroData } = props;

  const urlString = process.env.GATSBY_BASE_URL + slug;

  let heroTitle, heroDescription, heroImage;
  if (heroData?.blockContent?._rawContent != null) {
    heroData?.blockContent?._rawContent.forEach((content: any) => {
      const tag = content.style ?? "normal";
      if (/^h\d/.test(tag)) {
        if (content.children == null) return;
        heroTitle = content.children
          .map((child: any) => child.text.trim())
          .join(" ");
      } else {
        if (content.children == null) return;
        heroDescription = content.children
          .map((child: any) => child.text.trim())
          .join(" ");
      }
    });
  }
  if (
    heroData?.media &&
    heroData.media[0] &&
    isSanityImageWithMeta(heroData.media[0])
  ) {
    if (
      heroData.media[0].asset?.gatsbyImageData?.images?.fallback?.src != null
    ) {
      heroImage = heroData.media[0].asset.gatsbyImageData.images.fallback.src;
    }
  }

  const title =
    props.overwriteTitle ||
    (seoData?.pageTitle as string) ||
    heroTitle ||
    (data?.defaultSEO?.pageTitle as string);
  const description =
    seoData?.pageDescription ||
    (props.overwriteDescription as string) ||
    heroDescription ||
    (data?.defaultSEO?.pageDescription as string);
  const image =
    seoData?.ogImage?.asset?.url ||
    (props.overwriteImage as string) ||
    heroImage ||
    (data?.defaultSEO?.ogImage?.asset?.url as string);
  const keyword =
    seoData?.pageKeyWords || (data?.defaultSEO?.pageKeyWords as string);

  return (
    <Helmet title={title}>
      {alternateRegions &&
        alternateRegions.length > 0 &&
        alternateRegions.map((alt) => {
          if (alt == null || alt.isHidden === true) {
            return null;
          }
          return (
            <link
              key={alt.iban}
              rel="alternate"
              hrefLang={alt.hrefLang as string}
              href={setBaseUrl(slug, alt.iban as string)}
            />
          );
        })}
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword} />
      <html lang="en" />

      <meta property="og:title" content={title} />
      <meta property="og:type" content={props.post ? `article` : `website`} />
      <meta property="og:url" content={urlString} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:site" content={urlString} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;
