import React, { useState, useEffect, ChangeEvent } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query, SanityPrivacyPolicyPageConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { Container, FlexGrid, WrapperHeader } from "@util/standard";
import { BlocksContent, Modal, SwipeIndicator, ParallaxCache, ProviderContainer } from "@global";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { useStore } from "@state/store";
import { useCheckScreenWidth } from "@util/hooks";
import { scrollToTop } from "@util/helper";
import { Components } from "@components";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useHasMounted } from "@util/hooks";
import { setCookie, getCookie } from "@util/helper";

interface Data extends Query {
  altRegions: SanityPrivacyPolicyPageConnection;
}

interface Props extends PageProps {
  data: Data;
}

const Sidebar = styled(Container)`
  flex-flow: column wrap;
  padding-right: 80px;
  border-right: 1px solid ${colorsRGB.grey(0.2)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    padding-right: 0;
    border-right: none;
  }
`;

const PolicyTabbedContainer = styled(Container) <{
  isActive: boolean;
}>`
  flex-flow: column wrap;
  margin-bottom: 45px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  max-width: 300px;

  ${({ isActive }) => !isActive && `
    opacity: 0.5;
  `}

  &:hover{
    opacity: 1;
  }

  h5{
    margin-bottom: 5px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    opacity: 1;
    padding-bottom: 35px;
    margin-bottom: 0;
    &:not(:last-of-type){
      border-bottom: 1px solid ${colorsRGB.grey(0.2)};
      margin-bottom: 35px;
    }
  }
`;

export default function PolicyTemplate({ data, pageContext }: Props) {
  const { language, user } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const { nodes } = data.allSanityPrivacyPolicyPage;
  const [policyIndex, setPolicyIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const tagManagerCookie = "gatsby-gdpr-google-tagmanager";
  const facebookPixelCookie = "gatsby-gdpr-facebook-pixel";
  const hotjarCookie = "gatsby-gdpr-hotjar";
  const [tagManagerCookieValue, setTagManagerCookieValue] = useState("");
  const [facebookPixelValue, setFacebookPixelValue] = useState("");
  const [hotjarValue, setHotjarValue] = useState("");
  const hasMounted = useHasMounted();

  const {
    password,
    seo,
    mainContent,
    policyTabs,
    components,
  } = nodes[0];

  useEffect(() => {
    if (hasMounted) {
      setTagManagerCookieValue(user.cookieAcceptance === true ? getCookie(tagManagerCookie) : "");
      setFacebookPixelValue(user.cookieAcceptance === true ? getCookie(facebookPixelCookie) : "");
      setHotjarValue(user.cookieAcceptance === true ? getCookie(hotjarCookie) : "");
    }
  }, [hasMounted]);

  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);
  const policy = policyTabs && policyTabs[policyIndex];

  const handleTabClick = (index: number) => {
    if (!isTabletWidth && !isMobileWidth) {
      setPolicyIndex(index);
      scrollToTop(300);
    } else {
      //Open popup
      setPolicyIndex(index);
      setVisible(true);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const cookieName = target.name;
    const accept = target.checked === true ? "true" : "false";

    if (accept === "true") {
      useStore.setState({
        user: {
          ...user,
          cookieAcceptance: true,
        }
      });
    }

    if (cookieName === tagManagerCookie) setTagManagerCookieValue(accept);
    if (cookieName === facebookPixelCookie) setFacebookPixelValue(accept);
    if (cookieName === hotjarCookie) setHotjarValue(accept);

    setCookie(cookieName, accept, 7);

  };

  return (
    <>
      <SEO seoData={seo} alternateRegions={alternateRegions} slug={pageContext?.pagePath} />
      <ProviderContainer password={password} isAdmin={user?.isAdmin}>
        <ParallaxCache variables={[policyIndex]} />

        <WrapperHeader>
          <FlexGrid gridMargin="110px">
            <Container width="40%" tabletWidth="100%">
              <Sidebar>
                <Container margin="0 0 20px">
                  <BlocksContent data={mainContent} />
                </Container>

                <Container margin="0 0 50px">
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={tagManagerCookieValue === "true"} name={tagManagerCookie} onChange={handleChange} />} label="Google Tag Manager" />
                    <FormControlLabel control={<Checkbox checked={facebookPixelValue === "true"} name={facebookPixelCookie} onChange={handleChange} />} label="Facebook Pixel" />
                    <FormControlLabel control={<Checkbox checked={hotjarValue === "true"} name={hotjarCookie} onChange={handleChange} />} label="Hotjar" />
                  </FormGroup>
                </Container>

                {policyTabs && policyTabs.map((policyTab, index) => {
                  if (policyTab == null) return;
                  const isActive = index === policyIndex;

                  return (
                    <PolicyTabbedContainer
                      key={index}
                      className="rte"
                      isActive={isActive}
                      onClick={() => handleTabClick(index)}>
                      <h5>{policyTab.title}</h5>
                      <p>{policyTab.shortDescription}</p>
                      <SwipeIndicator
                        isRelative
                        isNext
                        color={isActive ? "green" : "grey"}
                        text={language?.tabbedView ?? "View"} />
                    </PolicyTabbedContainer>
                  );
                })}
              </Sidebar>
            </Container>
            {(!isTabletWidth && !isMobileWidth) ?
              <Container width="60%">
                {policy != null &&
                  <div className="rte">
                    <h5>{policy.title}</h5>
                    <BlocksContent data={policy.content} />
                  </div>
                }
              </Container>
              :
              <Modal visible={visible} setVisible={setVisible} width="100%" isPolicy>
                {policy != null &&
                  <div className="rte">
                    <h5>{policy.title}</h5>
                    <BlocksContent data={policy.content} />
                  </div>
                }
              </Modal>
            }
          </FlexGrid>
        </WrapperHeader>
        {
          components &&
          <Container flexDirection="column">
            {components.map(component => {
              if (component == null) return null;
              return (
                <Components key={component._key} data={component} />
              );
            })
            }
          </Container>
        }
      </ProviderContainer>
    </>
  );
}

export const query = graphql`
  query PolicyQuery($region: String, $slug: String) {
    allSanityPrivacyPolicyPage(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        password
        title
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        mainContent{
          _rawContent
        }
        policyTabs{
          title
          shortDescription
          content {
            _rawContent
          }
        }
        components {
          ... on SanityImagesContentBlock {
            ...sanityImagesContentBlock
          }
          ... on SanityImageContentCarouselBlock {
            ...sanityImageContentCarouselBlock
          }
          ... on SanityMediaContentBlock {
            ...sanityMediaContentBlock
          }
          ... on SanityFullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
        }
      }
    }
    altRegions: allSanityPrivacyPolicyPage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
